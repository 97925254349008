$(document).ready(function () {

  // Services page
  if ($("body").hasClass("single-services")) {
    $(".single_service_wrapper .owl-carousel").owlCarousel({
      loop: true,
      nav: true,
      items: 1,
      navText: [$('.single_service_wrapper .owl_prev_custom'), $('.single_service_wrapper .owl_next_custom')]
    });
  }

  // Sectors page
  if ($("body").hasClass("page-template-page-markets-and-information")) {

    $(".markets_and_information_wrapper .sector_image_carousel").each(function (i) {
      var that = this;
      $(this).owlCarousel({
        loop: true,
        nav: true,
        items: 1,
        navText: [$(that).next().find('.owl_prev_custom'), $(that).next().find('.owl_next_custom')]
      });
    });

    $(".drop_down_toggle select").on("change", function() {
      var gallery_number = $(this).val();
      $(".sector_gallery").removeClass("active");
      $("." + gallery_number).addClass("active");
    });

  }
});