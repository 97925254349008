// How to use set_col_heights
// $(window).load(function() {
//   if (Modernizr.mq('(min-width: 767px)')) {
//     set_col_heights('.feature_box .body');
//     set_col_heights('.home .output_review .comment');
//     set_col_heights('.blog_feed_heights');
//   }
// });

var set_col_heights = function (el, extra) {
  if (typeof extra === "undefined") {
    extra = 0;
  }
  if ($(el).css("min-height")) {
    TweenMax.set($(el), {
      clearProps: "min-height"
    });
  }
  // Get an array of all element heights
  var elementHeights = $(el).map(function () {
    return $(this).height();
  }).get();
  var maxHeight = Math.max.apply(null, elementHeights);
  // Set each height to the max height
  $(el).css("min-height", maxHeight + extra + "px");
};

var do_col_heights = function () {
  if (Modernizr.mq('(min-width: 767px)')) {
    if ($(window).width() > 767) {
      set_col_heights($(".sub_service_block"));
    }
    if ($(window).width() > 991) {
      set_col_heights($(".blog_title h3"));
      set_col_heights($(".blog_excerpt"));
      set_col_heights($(".case_study_quote"));
      set_col_heights($(".quote_footer"));
    } 
    // Fix for empty elements getting a height set on them
    $("p, h1, h2, h3, h4").each(function () {
      if ($(this).is(':empty')) {
        $(this).css("display", "none");
      }
    });
    $(".mini_grouped_slider .outer_image_wraps").each(function () {
      set_col_heights($(this).find(".business_desc_wrapper"));
    });
  }
};

// Use the set_outer_heights function if you are using also padding.
function set_outer_heights(height_to_compare) {
  var this_outerheight;
  var array_of_heights = [];

  $(height_to_compare).each(function () {
    this_outerheight = $(this).outerHeight(true);
    array_of_heights.push(this_outerheight);
  });

  var largest_height = Math.max.apply(null, array_of_heights);

  TweenMax.set($(height_to_compare), {
    minHeight: largest_height
  });
}

var do_outer_heights = function() {
  $(".do_outer_heights").each(function() {
    var that = this;
    if ($(window).width() > 991) {
      set_outer_heights("." + $(that).attr("class").split(' ')[0]);
    } else {
      $("." + $(that).attr("class").split(' ')[0]).css("min-height", "");
    }
  });
};

$(window).load(function () {
  do_col_heights();
  do_outer_heights();
});

$(window).resize(function () {
  do_col_heights();
  do_outer_heights();
});
